<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-08-27 11:28:50
 * @LastEditors: ------
 * @LastEditTime: 2021-09-17 10:05:35
-->
<template>
  <div class="newsdetail">
    <div class="detail_box">
      <div class="title">{{ detail.newsTitle }}</div>
      <div class="time">
        <span>{{ detail.createTime }}</span>
        <span>{{ detail.newsSource }}</span>
      </div>
      <!-- <div class="img"><img :src="detail.newsImg" alt="" /></div> -->
      <div v-html="detail.newsContent || ''" class="con"></div>
    </div>
    <div
      class="statement"
      v-if="
        detail.wechatArticleLinks == '' || detail.wechatArticleLinks == null
      "
    ></div>
    <div class="statement" v-else>
      [声明] 此篇文章摘自
      <a :href="detail.wechatArticleLinks" target="_blank">{{
        detail.wechatArticleLinks
      }}</a>
      ，转载目的在于传递更多信息。如涉及作品内容、版权和其它问题，请第一时间与本站联系，我们将在第一时间删除内容！<span
        >本站文章版权归原作者所有，内容为作者个人观点，本站仅提供参考。本站拥有对此声明的最终解释权!</span
      >
    </div>
  </div>
</template>

<script>
import { newsDetail } from "@/utils/request";
export default {
  name: "newsdetail",
  data() {
    return {
      detail: {},
    };
  },
  created() {
    newsDetail({ id: this.$route.query.id }).then((res) => {
      console.log(res);
      this.detail = res.data.result;
    });
  },
  methods: {},
};
</script>

<style scoped lang="less">
.detail_box {
  width: 92%;
  margin: auto;
  margin-top: 30px;
  font-size: 18px;
}
.title {
  font-size: 24px;
  font-weight: bold;
}
.img,
.time {
  margin-top: 20px;
  text-align: center;
}
.img img {
  width: 520px;
  height: 300px;
}
.time span {
  margin: 0 20px;
}

.con {
  margin-top: 30px;
  line-height: 30px;
  // text-indent: 2em !important;
  width: 100%;
  // font-family: "microsoft yahei" !important;
}
/deep/ .con span {
  font-size: 20px !important;
  line-height: 0.21rem !important;
  font-family: "microsoft yahei" !important;
  /* color: #f29800 !important; */
}
/deep/ .con img {
  width: 90% !important;
  height: auto !important;
  object-fit: contain !important;
}
/deep/ .con .MsoNormal {
  text-indent: 2em !important;
}
.statement {
  width: 56%;
  margin: 0 auto;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 30px;
  margin-top: 30px;
  border-top: 1px dashed #ccc;
  padding: 50px 60px 0 60px;
  line-height: 24px;
}
.statement a {
  width: 180px;
  display: inline-block;
  overflow: hidden;
  word-break: keep-all;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
}
</style>
